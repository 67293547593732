<template>
  <div class="tasks">
    <task-modal-change-status
      ref="changeStatusModal"
      v-model="dataChangeStatus.isShowChangeStatus"
      :new-status="dataChangeStatus.newStatus"
      :task-id="dataChangeStatus.id"
      @success-change="showStatusChangeSuccessNotice"
      @fail-change="showStatusChangeFailNotice"
      @cancel-change-status="cancelChangeStatus"
    />
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('tasks.back_to_tasks') }}</v-btn>
    </info-modal>
    <base-dialog ref="deleteDialog" :title="$t('task.delete_message')" :text="$t('task.delete_text')" />

    <entity-status-list :statuses="entityTasksStatuses">
      <div v-if="!media.isMobile" class="d-flex">
        <template v-if="!media.isMobile && canUpdate">
          <v-menu offset-y left>
            <template #activator="{ attrs, on }">
              <v-btn large class="mr-3 elevation-0" color="primary" v-bind="attrs" v-on="on">
                {{ $t('button.add') }}
                <v-icon right>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-if="accessToRequests" @click="issueTaskWithTemplate">
                {{ $t('button.task_with_template') }}
              </v-list-item>

              <v-list-item @click="issueTask">
                {{ $t('button.custom_task') }}
              </v-list-item>

              <v-list-item @click="issueRecurrenceTask">
                {{ $t('button.add_reccurence_task') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn
          v-if="status !== RECURRENCE"
          color="secondary"
          class="primary--text mr-3"
          large
          elevation="0"
          @click="exportTasks"
        >
          {{ $t('button.export') }}
          <v-icon right>mdi-file-download</v-icon>
        </v-btn>
      </div>
      <tasks-mobile-menu
        v-else-if="isDataMissing"
        :page="page"
        :status="status"
        :can-update="canUpdate"
        @export-tasks="exportTasks"
      />
    </entity-status-list>

    <div v-if="isLoading && viewMode !== 'kanban'" class="d-flex align-center justify-center" style="min-height:200px">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <tasks-missing-data v-else-if="isDataMissing" class="tasks__missing-data" :status="status" />

    <template v-else>
      <div v-if="media.isMobile" class="d-flex flex-column mb-1 mb-md-4">
        <mobile-sort class="mt-2" reverse :sort-list="tasksHeaders" :sort-by="activeHeader" :options.sync="options">
          <tasks-mobile-menu :page="page" :status="status" :can-update="canUpdate" @export-tasks="exportTasks" />
        </mobile-sort>

        <div class="d-flex align-center mb-5">
          <mobile-search-menu
            :key="$route.name"
            v-model="queryModel"
            :placeholder="$t('tasks.search')"
            single-line
            full-width
            hide-details
          />

          <icon-button @click="showModalFilter">
            <v-icon>
              mdi-filter
            </v-icon>
          </icon-button>
          <tasks-modal-filters v-model="isShowModalFilter" :filters.sync="filterList" :status="status" />
        </div>

        <toggle-view-mode
          v-if="media.isMobile && isAllStatus"
          v-model="viewMode"
          class="mb-4"
          @input="updateViewMode"
        />

        <tasks-mobile-list
          v-if="viewMode === 'table'"
          :tasks="tasks"
          :can-update="canUpdate"
          @change-status="changeStatus"
          @delete="deleteTask"
          @edit="editTask"
          @cancel-task="cancelTask"
          @open-detailed="openTask"
        />
      </div>

      <tasks-filter-list
        v-if="!media.isMobile"
        v-model="filterList"
        :status="status"
        :view-mode="viewMode"
        @update:viewMode="updateViewMode"
      />
      <div v-if="isAllStatus && viewMode === 'kanban'">
        <div class="kanban">
          <div v-for="(column, columnIndex) in columns" :key="columnIndex" cols="3" class="kanban__column">
            <div class="column-title text-h6 font-weight-medium pa-4 d-flex justify-space-between">
              {{ column.title }}
              <v-chip small class="ml-2" :color="getColumnColor(column.id)" text-color="white">
                {{ column.count }}
              </v-chip>
            </div>
            <v-progress-linear v-if="loadingKanban" indeterminate color="primary"></v-progress-linear>
            <v-divider></v-divider>
            <div class="pa-2">
              <draggable
                :ref="'draggable-' + column.id"
                :list="column.tasks"
                :group="getGroup(column.id)"
                class="task-list"
                :sort="false"
                :data-column-index="columnIndex"
                :data-column-status="column.id"
                :disabled="media.isMobile"
                @end="onTaskEnd($event)"
              >
                <kanban-card
                  v-for="task in column.tasks"
                  :key="task.id"
                  :column="column"
                  :task="task"
                  :can-update="canUpdate"
                  :data-task-id="task.id"
                  :back-route="backRoute"
                  @change-status="changeStatus"
                  @delete-task="deleteTask"
                  @edit-task="editTask"
                />
              </draggable>
            </div>
            <div v-if="loadingKanban" class="d-flex align-center justify-center pb-4">
              <v-progress-circular color="primary" size="32" indeterminate />
            </div>
          </div>
        </div>
        <div v-if="!loadingKanban && showButtonLoadMore" class="d-flex align-center justify-center mt-10">
          <v-btn color="primary" text @click="loadMore">{{ $t('button.load_more') }}</v-btn>
        </div>
      </div>

      <template v-else>
        <base-table
          v-if="!media.isMobile"
          hide-default-footer
          :items="tasks"
          :item-class="rowClass"
          :headers="tasksHeaders"
          :loading="tableLoading"
          :options.sync="options"
          :server-items-length="tasksLimit"
          @click:row="openTask"
        >
          <template v-slot:item.status="{ item }">
            <span :class="getClassStatus(item.status)">{{ item.status && $t(`tasks.${item.status}`) }}</span>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt || '-' }}
          </template>
          <template v-slot:item.deadlineDate="{ item }">
            <span :class="{ 'red--text': item.isOverdue }">{{ item.deadlineDate || '-' }}</span>
          </template>
          <template v-slot:item.roles="{ item }">
            {{ joinWithLimit(item.roles, 2) }}
          </template>
          <template v-slot:item.menu="{ item }">
            <task-menu
              v-if="showTaskMenu(item)"
              color="blue50"
              :status="item.status"
              :can-update="canUpdate"
              @change-status="changeStatus({ ...$event, id: item.id })"
              @delete="deleteTask(item.id)"
              @edit="editTask(item)"
            />
          </template>
        </base-table>
        <base-pagination v-if="pageCount" v-model="pageModel" :length="pageCount" class="mt-10" />
      </template>
    </template>
  </div>
</template>

<script>
import EntityStatusList from '@/components/EntityStatusList.vue';
import TasksMissingData from '@/components/Tasks/MissingData.vue';
import TasksFilterList from '@/components/Tasks/FilterList.vue';
import BaseTable from '@/components/BaseTable.vue';
import TaskMenu from '@/components/Tasks/TaskMenu.vue';
import MobileSort from '@/components/MobileSort/index.vue';
import TasksMobileMenu from '@/components/Tasks/Menu.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import IconButton from '@/components/IconButton.vue';
import TasksModalFilters from '@/components/Tasks/ModalFilterList.vue';
import ToggleViewMode from '@/components/Tasks/ToggleViewMode.vue';
import TasksMobileList from '@/components/Tasks/MobileList.vue';
import BasePagination from '@/components/BasePagination.vue';
import TaskModalChangeStatus from '@/components/Tasks/ModalChangeStatus.vue';
import InfoModal from '@/components/InfoModal.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import { mapGetters } from 'vuex';

import { createModelData } from '@/schemas/createModelData';
import { schemaForDesktop } from '@/schemas/tasksFilterList.schema';

import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

import {
  TASKS,
  TASKS_ALL,
  TASKS_IN_PROGRESS,
  TASKS_CANCELED_AND_COMPLETED,
  TASKS_RECURRENCE,
  TASKS_CREATE_WITH_TEMPLATE,
  TASKS_CREATE,
  TASKS_RECURRENCE_CREATE,
  TASKS_DETAILED,
  TASKS_RECURRENCE_DETAILED,
  TASKS_EDIT,
  TASKS_RECURRENCE_EDIT,
} from '@/constants/routes';
import { RECURRENCE } from '@/constants/entityStatuses';
import * as actions from '@/constants/actions';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';
import * as taskStatuses from '@/constants/taskStatuses';
import { TASKS_LIST } from '@/constants/analyticsActions';

import { flushPromises } from '@/utils/scheduler';
import { translateDate } from '@/utils/dateFormatting';
import format from 'date-fns/format';
import { throttle } from '@/utils/delay';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import { camelToSnake } from '@/utils/formatters';
import { isEqual } from '@/utils/common';
import joinWithLimit from '@/utils/joinWithLimit';

import client from '@/http/client';
import issuesService from '@/services/issues';
import analyticsService from '@/services/analytics';

import draggable from 'vuedraggable';
import KanbanCard from '@/components/Tasks/KanbanCard.vue';

export default {
  name: 'Tasks',
  components: {
    EntityStatusList,
    TasksMissingData,
    TasksFilterList,
    BaseTable,
    TaskMenu,
    MobileSort,
    TasksMobileMenu,
    MobileSearchMenu,
    IconButton,
    TasksModalFilters,
    ToggleViewMode,
    TasksMobileList,
    BasePagination,
    TaskModalChangeStatus,
    InfoModal,
    BaseDialog,
    KanbanCard,
    draggable,
  },
  inject: ['media'],
  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      loadingKanban: false,
      query: '',
      page: +this.$route.query.page || 1,
      pageCount: 1,
      isDataMissing: false,
      filterList: createModelData(schemaForDesktop),
      tasks: [],
      options: { sortBy: '', sortDesc: null },
      tasksLimit: 10,
      tableLoading: false,
      isShowModalFilter: false,
      dataChangeStatus: {
        isShowChangeStatus: false,
        newStatus: '',
        oldStatus: '',
        id: 0,
      },
      dataDeleteTask: {
        isShowModalDelete: false,
        taskId: 0,
      },
      modal: {
        show: false,
        message: '',
        info: true,
      },
      RECURRENCE,
      oldStatus: null,
      newStatus: null,
      columns: [
        {
          id: taskStatuses.PENDING,
          title: 'Pending',
          count: null,
          offset: 0,
          tasks: [],
        },
        {
          id: taskStatuses.IN_PROGRESS,
          title: 'In progress',
          count: null,
          offset: 0,
          tasks: [],
        },
        {
          id: taskStatuses.COMPLETED,
          title: 'Completed',
          count: null,
          offset: 0,
          tasks: [],
        },
        {
          id: taskStatuses.CANCELED,
          title: 'Canceled',
          count: null,
          offset: 0,
          tasks: [],
        },
      ],
      TASKS_DETAILED,
      viewMode: 'table',
      showConfirmDialog: false,
      draggedTask: null,
      sourceColumnIndex: null,
      targetColumnIndex: null,
      sourceTaskIndex: null,
      targetTaskIndex: null,
    };
  },
  computed: {
    entityTasksStatuses() {
      return [
        { id: 0, text: this.$t('tasks.pending'), name: TASKS },
        { id: 1, text: this.$t('tasks.in_progress'), name: TASKS_IN_PROGRESS },
        { id: 2, text: this.$t('tasks.canceled_and_completed'), name: TASKS_CANCELED_AND_COMPLETED },
        { id: 3, text: this.$t('tasks.all'), name: TASKS_ALL },
        {
          id: 4,
          text: this.$t('tasks.recurrence'),
          name: TASKS_RECURRENCE,
          tooltip: this.$t('task.recurrence_tab_tooltip'),
        },
      ];
    },
    isAllStatus() {
      return this.status === taskStatuses.ALL;
    },
    isRecurrence() {
      return this.status === RECURRENCE;
    },
    taskRoute() {
      return { name: TASKS_CREATE };
    },
    backRoute() {
      return this.$route;
    },
    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),
    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
    accessToRequests() {
      return this.$can(actions.READ, subjects.REQUESTS);
    },
    sortableStatus() {
      if (this.status === taskStatuses.PENDING || this.status === taskStatuses.IN_PROGRESS) {
        return false;
      }
      return true;
    },
    tasksHeaders() {
      let headers = null;
      if (this.isRecurrence) {
        headers = [
          { text: this.$t('tasks.title'), value: 'name', sortable: true, width: '12%' },
          { text: this.$t('tasks.project'), value: 'project', sortable: true, width: '12%' },
          { text: this.$t('tasks.building'), value: 'building', sortable: true, width: '12%' },
          { text: this.$t('tasks.unit'), value: 'unit', sortable: true, width: '12%' },
          { text: this.$t('tasks.room'), value: 'room', sortable: true, width: '12%' },
          {
            text: this.$t('task.first_task'),
            value: 'issueDateFirst',
            width: '14%',
          },
          {
            text: this.$t('task.last_task'),
            value: 'issueDateLast',
            width: '14%',
          },
          {
            text: this.$t('task.reccurence_period'),
            value: 'recurrencePeriod',
            width: '15%',
          },
          { text: '', value: 'menu', sortable: false, width: '4%' },
        ];
      } else {
        headers = [
          {
            text: '№',
            value: 'managementCompanyIssueNumber',
            sortable: true,
            sortValue: 'id',
            width: '4%',
          },
          {
            text: this.$t('tasks.date_create'),
            sortable: true,
            value: 'createdAt',
            width: '9%',
          },
          {
            text: this.$t('tasks.deadline_date'),
            sortable: true,
            value: 'deadlineDate',
            width: '9%',
          },
          {
            text: this.$t('label.title'),
            value: 'name',
            sortable: true,
            width: '9%',
          },
          {
            text: this.$t('label.project'),
            value: 'project',
            sortable: true,
            width: '9%',
          },
          {
            text: this.$t('label.building'),
            value: 'building',
            sortable: true,
            width: '9%',
          },
          {
            text: this.$t('label.unit'),
            value: 'unit',
            sortable: true,
            width: '9%',
          },
          {
            text: this.$t('label.room'),
            value: 'room',
            sortable: true,
            width: '9%',
          },
          {
            text: this.$t('label.roles'),
            value: 'roles',
            sortable: false,
            width: '9%',
          },
          {
            text: this.$t('label.status'),
            value: 'status',
            sortable: this.sortableStatus,
            width: '10%',
          },
          {
            text: '',
            value: 'menu',
            sortable: false,
            width: '4%',
          },
        ];
      }

      return this.accessToRooms ? headers : headers.filter(item => item.value !== 'room');
    },
    activeHeader() {
      return this.tasksHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },
    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: value },
        });
      },
    },
    queryModel: {
      get() {
        return this.query;
      },

      set(value) {
        this.query = value;
      },
    },
    offset() {
      return (this.page - 1) * this.tasksLimit;
    },
    canUpdate() {
      return this.$can(UPDATE, subjects.ISSUES);
    },
    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.tasksHeaders.find(tasksHeaders => tasksHeaders.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },
    showButtonLoadMore() {
      for (let i = 0; i < this.columns.length; i += 1) {
        if (this.columns[i].count > this.columns[i].tasks.length) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    status(newStatus, oldStatus) {
      this.$nextTick(async () => {
        if (newStatus === RECURRENCE || oldStatus === RECURRENCE) {
          this.options = { sortBy: '', sortDesc: null };
        }
        if (this.isDataMissing) {
          this.isLoading = true;
          this.isDataMissing = false;
        }
        this.tasks = [];
        this.pageModel = 1;
        await this.getTasks().then(() => {
          // if (!this.tasks.length && !this.query) {
          //   this.isDataMissing = true;
          // }
        });
      });

      this.query = '';
    },

    filterList: {
      handler(newValue, oldValue) {
        if (isEqual(newValue, oldValue)) {
          return;
        }
        this.queryModel = newValue.searchBar;
        this.pageModel = 1;
        if (this.viewMode === 'kanban') {
          this.columns = this.columns.map(column => {
            return {
              ...column,
              count: null,
              offset: 0,
              tasks: [],
            };
          });
          this.$options.throttledSearchKanban();
        } else {
          this.$options.throttledSearchTable();
        }
      },
      deep: true,
    },

    orderBy() {
      this.getTasks();
    },

    page() {
      this.getTasks();
    },

    // eslint-disable-next-line
    '$route.query.page': function(newValue) {
      if (newValue) {
        if (this.pageModel === +newValue) {
          return;
        }
        this.page = +newValue || 1;
      }
    },

    // eslint-disable-next-line
    // '$route.name': function(newValue, oldValue) {
    //   if (newValue === oldValue) return;
    //   if (this.isDataMissing) {
    //     this.isDataMissing = false;
    //   }
    //   if (this.pageModel !== 1) {
    //     this.pageModel = 1;
    //     return;
    //   }
    //   this.getTasks();
    // },

    queryModel(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.pageModel = 1;
      if (this.viewMode === 'kanban') {
        this.$options.throttledSearchKanban();
      } else {
        this.$options.throttledSearchTable();
      }
    },
  },
  beforeMount() {
    this.$options.throttledSearchTable = throttle(() => {
      this.getTasks();
    }, 500);

    this.$options.throttledSearchKanban = throttle(() => {
      this.getKanbanTasks();
    }, 500);
  },
  mounted() {
    analyticsService.track(TASKS_LIST);
    this.isLoading = true;

    if (this.$route.query.page === undefined) {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, page: this.pageModel },
      });
    }

    if (this.isAllStatus) {
      this.viewMode = this.$route.query.mode || 'table';

      if (this.viewMode === 'kanban') {
        this.getKanbanTasks();
        this.$router.push({
          path: this.$route.path,
          query: { mode: this.viewMode },
        });
      } else {
        this.getTasks()
          .then(() => {
            if (this.tasks.length === 0 && !this.query) {
              this.isDataMissing = true;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    } else {
      this.getTasks()
        .then(() => {
          if (this.tasks.length === 0 && !this.query) {
            this.isDataMissing = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    updateViewMode(mode) {
      this.viewMode = mode;
      this.pageModel = 1;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, mode, page: this.pageModel },
      });

      const fetch = mode === 'table' ? this.getTasks : this.getKanbanTasks;
      fetch();
    },
    toggleView() {
      this.viewMode = this.viewMode === 'table' ? 'kanban' : 'table';
      this.$emit('change-view', this.viewMode);
    },
    getColumnColor(columnId) {
      const colors = {
        pending: '#FFA000',
        in_progress: '#1E88E5',
        completed: '#43A047',
        canceled: '#E53935',
      };
      return colors[columnId] || '#757575';
    },
    getGroup(columnId) {
      switch (columnId) {
        case taskStatuses.PENDING:
          return { name: taskStatuses.PENDING, pull: true, put: false };
        case taskStatuses.IN_PROGRESS:
          return {
            name: taskStatuses.IN_PROGRESS,
            pull: true,
            put(to, from) {
              return from.options.group.name === taskStatuses.PENDING;
            },
          };
        case taskStatuses.COMPLETED:
          return {
            name: taskStatuses.COMPLETED,
            pull: false,
            put(to, from) {
              return from.options.group.name === taskStatuses.IN_PROGRESS;
            },
          };
        case taskStatuses.CANCELED:
          return {
            name: taskStatuses.CANCELED,
            pull: false,
            put(to, from) {
              return (
                from.options.group.name === taskStatuses.PENDING || from.options.group.name === taskStatuses.IN_PROGRESS
              );
            },
          };
        default:
          return { name: 'tasks' };
      }
    },
    onTaskEnd(evt) {
      // Сохраняем исходные данные о задаче и её перемещении
      this.sourceColumnIndex = evt.from.dataset.columnIndex;
      this.targetColumnIndex = evt.to.dataset.columnIndex;

      if (this.sourceColumnIndex === this.targetColumnIndex) {
        this.sourceColumnIndex = null;
        this.targetColumnIndex = null;
        return;
      }
      this.sourceTaskIndex = evt.oldIndex;
      this.targetTaskIndex = evt.newIndex;
      // Получаем перетаскиваемую задачу
      this.draggedTask = this.columns[this.targetColumnIndex].tasks[this.targetTaskIndex];
      // Показываем модальное окно для подтверждения
      this.changeStatus({ newStatus: evt.to.dataset.columnStatus, id: +evt.item.dataset.taskId });
    },
    changeStatus({ newStatus, oldStatus, id }) {
      if (!id) return;
      this.dataChangeStatus.isShowChangeStatus = true;
      this.dataChangeStatus.newStatus = newStatus;
      this.dataChangeStatus.oldStatus = oldStatus;
      this.dataChangeStatus.id = id;
    },
    moveTask(taskId, fromColumnId, toColumnId, toIndex) {
      const fromColumn = this.columns.find(col => col.id === fromColumnId);
      if (!fromColumn) return;

      const taskIndex = fromColumn.tasks.findIndex(task => task.id === taskId);
      if (taskIndex === -1) return;

      const task = fromColumn.tasks[taskIndex];
      fromColumn.tasks.splice(taskIndex, 1);

      const toColumn = this.columns.find(col => col.id === toColumnId);
      if (!toColumn) return;

      toColumn.tasks.splice(toIndex, 0, task);

      fromColumn.count = fromColumn.tasks.length;
      toColumn.count = toColumn.tasks.length;
    },
    cancelChangeStatus() {
      if (!this.targetColumnIndex) return;
      const task = this.columns[this.targetColumnIndex].tasks.splice(this.targetTaskIndex, 1)[0];
      this.columns[this.sourceColumnIndex].tasks.splice(this.sourceTaskIndex, 0, task);

      this.draggedTask = null;
      this.sourceColumnIndex = null;
      this.targetColumnIndex = null;
      this.sourceTaskIndex = null;
      this.targetTaskIndex = null;
    },
    async deleteTask(issueId) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (needDelete) {
        this.tableLoading = true;

        const deleteIssue = this.isRecurrence ? issuesService.deleteRecurrenceIssueById : issuesService.deleteIssueById;
        try {
          await deleteIssue(issueId);
          this.showModal(this.$t('tasks.success_delete_task'));
          this.getTasks();
        } catch (e) {
          this.showModal(this.$t('tasks.fail_delete_task'));
        } finally {
          this.tableLoading = false;
        }
      }
    },
    issueTaskWithTemplate() {
      this.$router.push({ name: TASKS_CREATE_WITH_TEMPLATE, params: { prevPage: this.backRoute } });
    },
    issueTask() {
      this.$router.push({ name: TASKS_CREATE, params: { prevPage: this.backRoute } });
    },
    issueRecurrenceTask() {
      this.$router.push({ name: TASKS_RECURRENCE_CREATE, params: { prevPage: this.backRoute } });
    },
    openTask({ id }) {
      if (this.isRecurrence) {
        this.$router.push({ name: TASKS_RECURRENCE_DETAILED, params: { id, prevPage: this.backRoute } });
      } else this.$router.push({ name: TASKS_DETAILED, params: { id, prevPage: this.backRoute } });
    },
    editTask({ id }) {
      if (this.isRecurrence) {
        this.$router.push({ name: TASKS_RECURRENCE_EDIT, params: { id, prevPage: this.backRoute } });
      } else this.$router.push({ name: TASKS_EDIT, params: { id, prevPage: this.backRoute } });
    },
    cancelTask(id) {
      this.changeStatus({ newStatus: taskStatuses.CANCELED, id });
    },
    showStatusChangeSuccessNotice() {
      this.showModal(this.$t('tasks.success_change_status'));
      if (this.viewMode === 'table') {
        this.getTasks();
      }
      this.moveTask(this.dataChangeStatus.id, this.dataChangeStatus.oldStatus, this.dataChangeStatus.newStatus, 0);
      this.draggedTask = null;
      this.sourceColumnIndex = null;
      this.targetColumnIndex = null;
      this.sourceTaskIndex = null;
      this.targetTaskIndex = null;
    },
    showStatusChangeFailNotice() {
      this.showModal(this.$t('tasks.fail_change_status'));
      this.draggedTask = null;
      this.sourceColumnIndex = null;
      this.targetColumnIndex = null;
      this.sourceTaskIndex = null;
      this.targetTaskIndex = null;
    },
    exportTasks() {
      const filter = {
        status: this.status,
        projects: this.normalizeItemFilter(this.filterList.projects),
        buildings: this.normalizeItemFilter(this.filterList.buildings),
        units: this.normalizeItemFilter(this.filterList.units),
        rooms: this.normalizeItemFilter(this.filterList.rooms),
        roles: this.normalizeItemFilter(this.filterList.roles),
        // clients: this.normalizeItemFilter(this.filterList.clients),
        forMe: this.filterList.myTasks,
        isOverdue: this.filterList.overdueTasks,
      };
      issuesService.downloadTasks(filter, 'tasks.xlsx');
    },
    async getTasks() {
      if (this.$options.cancelRequestTasks) {
        this.$options.cancelRequestTasks();
        await flushPromises();
      }

      const requestBody = {
        projects: this.normalizeItemFilter(this.filterList.projects),
        buildings: this.normalizeItemFilter(this.filterList.buildings),
        units: this.normalizeItemFilter(this.filterList.rooms) || this.normalizeItemFilter(this.filterList.units),
        roles: this.normalizeItemFilter(this.filterList.roles),
        clients: this.normalizeItemFilter(this.filterList.clients),
      };

      const orderBy =
        !this.sortableStatus && this.orderBy && this.orderBy.includes('status') ? undefined : this.orderBy;

      const params = this.isRecurrence
        ? {
            limit: this.tasksLimit,
            offset: this.offset,
            search: this.query,
            orderBy,
            forMe: this.filterList.myTasks,
          }
        : {
            limit: this.tasksLimit,
            offset: this.offset,
            search: this.query,
            orderBy,
            status: this.status,
            forMe: this.filterList.myTasks,
            isOverdue: this.filterList.overdueTasks || undefined,
          };

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelRequestTasks = cancelSource.cancel;
        const config = { cancelToken: cancelSource.token };
        this.tableLoading = true;
        const getTasks = this.isRecurrence ? issuesService.getRecurrenceIssue : issuesService.getIssue;

        const { count, results } = await getTasks({ requestBody, params, config });

        this.pageCount = Math.ceil(count / this.tasksLimit);

        this.tasksCount = count;

        this.tasks = this.isRecurrence
          ? results.map(contract => this.normalizeRecurrenceData(contract))
          : results.map(contract => this.normalizeData(contract));
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
        this.$options.cancelRequestTasks = null;
      }
    },
    async getKanbanTasks() {
      if (this.$options.cancelRequestTasks) {
        this.$options.cancelRequestTasks();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequestTasks = cancelSource.cancel;
      const config = { cancelToken: cancelSource.token };

      this.loadingKanban = true;

      const requestBody = {
        projects: this.normalizeItemFilter(this.filterList.projects),
        buildings: this.normalizeItemFilter(this.filterList.buildings),
        units: this.normalizeItemFilter(this.filterList.rooms) || this.normalizeItemFilter(this.filterList.units),
        roles: this.normalizeItemFilter(this.filterList.roles),
        clients: this.normalizeItemFilter(this.filterList.clients),
      };

      const params = {
        limit: this.tasksLimit,
        search: this.query,
        forMe: this.filterList.myTasks,
        isOverdue: this.filterList.overdueTasks || undefined,
      };

      try {
        this.columns = await Promise.all(
          this.columns.map(async column => {
            if ((column.count !== 0 && !column.count) || column.count) {
              const { count, results } = await issuesService.getIssue({
                requestBody,
                params: { ...params, offset: column.offset, status: column.id },
                config,
              });

              const tasks = [...column.tasks, ...results.map(task => this.normalizeData(task))];
              return {
                ...column,
                count,
                offset: tasks.length,
                tasks,
              };
            }
            return { ...column };
          })
        );
      } finally {
        this.isLoading = false;
        this.loadingKanban = false;
      }
    },
    loadMore() {
      this.getKanbanTasks();
    },
    normalizeData(task) {
      return {
        id: task.id,
        managementCompanyIssueNumber: task.managementCompanyIssueNumber,
        name: task.name,
        createdAt: this.formatDate(task.createdAt),
        deadlineDate: this.formatDate(task.deadlineDate),
        project: task.project.name,
        building: task.building.name,
        unit: task.unit.name,
        room: task.room?.name || '-',
        roles: task.currentStatusRoles,
        status: task.status,
        client: task.client ? `${task.client?.firstName} ${task.client?.lastName}` : null,
        clientType: task.clientType ? this.$t(`client.${task.clientType}`) : null,
        forMe: task.forMe,
        isOverdue: task.isOverdue,
      };
    },
    normalizeRecurrenceData(task) {
      return {
        id: task.id,
        createdAt: task.createdAt,
        name: task.name,
        project: task.project.name,
        building: task.building.name,
        unit: task.unit.name,
        room: task.room?.name || '-',
        // performer: `${task.performer.firstName} ${task.performer.lastName}`,
        issueDateFirst: this.formatDate(task.issueDateFirst),
        issueDateLast: this.formatDate(task.issueDateLast),
        recurrencePeriod: this.$t(task.recurrencePeriod.name),
        client: task.client ? `${task.client.firstName} ${task.client.lastName}` : null,
      };
    },
    normalizeItemFilter(itemFilter) {
      return checkEmptyParams(itemFilter) ? undefined : extractParamsFromMultiSelectObject(itemFilter);
    },
    formatDate(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L yyyy'));
      }
      return date;
    },
    rowClass(item) {
      return item.status === taskStatuses.CANCELED ? 'tasks__row--canceled' : '';
    },
    getClassStatus(status) {
      if (status === taskStatuses.PENDING) {
        return 'tasks__status--pending';
      }
      if (status === taskStatuses.IN_PROGRESS) {
        return 'tasks__status--in-progress';
      }
      return '';
    },
    showModalFilter() {
      this.isShowModalFilter = true;
    },
    showTaskMenu(item) {
      if (item.status === taskStatuses.COMPLETED) return false;
      if (item.status === taskStatuses.CANCELED) return false;
      if (item.forMe) return true;
      if (!this.canUpdate) return false;
      return true;
    },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    joinWithLimit,
  },
  // $options
  cancelRequestTasks: null,
};
</script>

<style lang="scss">
.tasks {
  height: 100%;
  &__missing-data {
    height: 100%;
  }
  &__row--canceled {
    color: $--grey-color-0;
  }
  &__status--pending {
    color: $--blue-color-20;
  }
  &__status--in-progress {
    color: $--blue-color-20;
  }
  &__status--canceled {
    color: $--grey-color-0;
  }
}

.kanban {
  position: relative;
  display: flex;
  column-gap: 12px;
  width: 100%;
  overflow: auto;

  &__column {
    flex: 0 0 auto;
    position: relative;
    background-color: #f5f5f5 !important;
    border-radius: 8px;
    width: calc(25% - 9px);
    min-width: 350px;

    @media (max-width: map.get($--screens, 'md')) {
      min-width: 280px;
    }
  }
}

.column-title {
  color: #1829d0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #f5f5f5;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #1829d0;
}
</style>
