<template>
  <div class="tasks-filters d-flex flex-column">
    <form-builder
      ref="formBuilder"
      v-model="valueModel"
      enable-cache
      remove-label-suffix
      :schema="schema"
      :initial-data="value"
    >
    </form-builder>

    <toggle-view-mode v-if="showSwitchViewMode" v-model="viewModeModel" />
  </div>
</template>

<script>
import { schema, schemaRules, schemaForDesktop, schemaForDesktopRules } from '@/schemas/tasksFilterList.schema';
import { RECURRENCE } from '@/constants/entityStatuses';
import { ALL } from '@/constants/taskStatuses';
import { createModelWrapper } from '@/utils/components';

import FormBuilder from '@/components/schema/FormBuilder.vue';
import ToggleViewMode from './ToggleViewMode.vue';

export default {
  name: 'TasksFilterList',
  components: { FormBuilder, ToggleViewMode },
  inject: ['media'],
  props: {
    value: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    viewMode: {
      type: String,
      required: true,
    },
  },

  computed: {
    viewModeModel: createModelWrapper('viewMode', 'update:viewMode'),
    schema() {
      if (this.media.isMobile) {
        return this.status === RECURRENCE ? schemaRules : schema;
      }

      return this.status === RECURRENCE ? schemaForDesktopRules : schemaForDesktop;
    },
    showSwitchViewMode() {
      return this.status === ALL && !this.media.isMobile;
    },
    valueModel: createModelWrapper('value', 'input'),
  },
};
</script>

<style lang="scss">
.tasks-filters {
  margin-bottom: 32px;
  .form-flex-row {
    flex-wrap: wrap;

    &__item-searchBar {
      flex-grow: 4;
      margin-right: 80px;
    }
    &__item-overdueTasks,
    &__item-myTasks {
      flex-grow: 0;
      margin-left: 16px;
    }
  }
}
</style>
