<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" :class="['task-card mb-3 rounded-lg transition-swing', `task-${column.id}`]">
      <div class="task-header pa-3 d-flex justify-space-between align-center">
        <span class="task-number font-weight-bold">#{{ task.managementCompanyIssueNumber }}</span>
        <task-menu
          v-if="showTaskMenu"
          :color="hover ? '#1829d0' : 'blue50'"
          :status="task.status"
          :can-update="canUpdate"
          @change-status="$emit('change-status', { ...$event, id: task.id })"
          @delete="$emit('delete-task', task.id)"
          @edit="$emit('edit-task', task)"
        />
      </div>
      <v-card-text class="pt-0">
        <router-link tag="div" :to="{ name: TASKS_DETAILED, params: { id: task.id, prevPage: backRoute } }">
          <div class="task-title font-weight-medium mb-2">{{ task.name }}</div>
          <v-row dense>
            <v-col cols="6">
              <div class="label">Created</div>
              <div class="value"><v-icon small color="grey" class="mr-1">mdi-calendar</v-icon>{{ task.createdAt }}</div>
            </v-col>
            <v-col v-if="task.deadlineDate" cols="6">
              <div class="label">Deadline</div>
              <div class="value">
                <v-icon small color="grey" class="mr-1">mdi-clock-outline</v-icon>{{ task.deadlineDate }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row dense>
            <v-col v-if="task.client" cols="12" md="6">
              <div class="value"><v-icon small color="grey" class="mr-1">mdi-account</v-icon>{{ task.client }}</div>
            </v-col>
            <v-col v-if="task.clientType" cols="12" md="6">
              <div class="value">
                <v-icon small color="grey" class="mr-1">mdi-account-key</v-icon>{{ task.clientType }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row dense>
            <v-col cols="12" lg="6">
              <div class="value">
                <v-icon small color="#1829d0" class="mr-1">mdi-office-building</v-icon>{{ task.project }}
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="value"><v-icon small color="#1829d0" class="mr-1">mdi-home</v-icon>{{ task.building }}</div>
            </v-col>
            <v-col cols="12">
              <div class="value"><v-icon small color="#1829d0" class="mr-1">mdi-door</v-icon>{{ task.unit }}</div>
            </v-col>
            <v-col v-if="task.room && task.room !== '-'" cols="12">
              <div class="value"><v-icon small color="#1829d0" class="mr-1">mdi-bed</v-icon>{{ task.room }}</div>
            </v-col>
          </v-row>
        </router-link>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { TASKS, TASKS_DETAILED } from '@/constants/routes';
import * as taskStatuses from '@/constants/taskStatuses';
import TaskMenu from '@/components/Tasks/TaskMenu.vue';

export default {
  name: 'KanbanCard',
  components: { TaskMenu },
  props: {
    task: {
      type: Object,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    backRoute: {
      type: Object,
      default: () => ({ name: TASKS }),
    },
  },
  data() {
    return {
      TASKS_DETAILED,
    };
  },
  computed: {
    showTaskMenu() {
      if (this.task.status === taskStatuses.COMPLETED) return false;
      if (this.task.status === taskStatuses.CANCELED) return false;
      if (this.task.forMe) return true;
      if (!this.canUpdate) return false;
      return true;
    },
  },
};
</script>

<style lang="scss">
.task-card {
  cursor: pointer;
  transition: all 0.3s;
  border-left: 4px solid #e6ebff !important;

  &:hover {
    border-color: #1829d0 !important;
  }
}

.task-card:hover {
  transform: translateY(-2px);
}

.task-number {
  color: #1829d0;
}

.task-title {
  font-size: 14px;
}

.task-date,
.task-client {
  color: #666;
}

.label {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2px;
}
.value {
  font-size: 0.875rem;
  line-height: 1.2;
  display: flex;
  align-items: start;
}
.v-icon {
  margin-right: 4px;
}
</style>
