<template>
  <v-btn-toggle v-model="valueModel" color="#1829d0" class="ms-auto">
    <v-btn outlined small value="table">
      <v-icon class="me-0" :color="value === 'table' ? '#1829d0' : ''">mdi-table</v-icon>
    </v-btn>
    <v-btn outlined small value="kanban">
      <v-icon class="me-0" :color="value === 'kanban' ? '#1829d0' : ''">mdi-view-week</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'ToggleViewMode',
  inject: ['media'],
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
  },
};
</script>
